@import url(https://use.typekit.net/uqy0veg.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
html, body {
    max-width: 100%;
    overflow-x: hidden;
}



body {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
}

.row {
    margin: 0px;
}

.contentContainer {
    max-width: 1140px;
    /* margin:auto; */
}

.mainContainer {
    max-width: 100%;
    background-color: #ffffff;
    overflow-x: hidden;
}

#videoBg {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.panelWithImageBackground {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.headerImg img{
    width: 75%;
}

/* .headerImg.right img{
    width: 36%;
} */

.headerImg.left {
    text-align: left;
}

.panelHeading {
    color: #ffffff;    
    text-align: center;
    max-width: 600px;
    
}

.panelHeading  h1 {   
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
}

.panelHeading  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    background-color: #a0c36c;
    padding-top:15px;
    padding-bottom:15px;
    border-radius: 5px;
}

.header-cta {
    padding:20px 10px;
    background:#ed1c24;
    color:#fff;
    text-align: center;
    line-height: 36px;
    font-weight: bold;
}



/* ================ */

.stickyLogo {
    position: absolute;
    max-width: 220px;
    margin-top: 0px;
    z-index: 1000;
    transition: 1s; 
    image-rendering: -webkit-optimize-contrast;
    top:5px;
}

.smallLogo {
    position: absolute;
    max-height: 100px;
    margin-top: 18px;
}

.countdown {
    height: 65px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color:#ffffff;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}



.countdown .CircularProgressbar {
    width: 40px !important;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
}

.countdown .counterLabel {
    margin-left: 5px;
    margin-right: 10px;
}

.countdown .counterText {
    text-align: "center";
    font-size: 20px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background-color: transparent !important;
    border: none !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: #fff !important;
}

.button-with-bg-purple {
    background: url(/images/button-bg-purple.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    height: 70px;
    width: 200px;
    max-width: 200px;
    margin: 0px !important;

    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-size: 24px;
    font-weight: 700;

    border: none;
    transition: -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
    transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;

}

.button-with-bg-purple:active,
.button-with-bg-purple:focus,
.button-with-bg-purple:hover {
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.button-with-bg-purple:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.page-header-page-margin {
    height: 135px;
    /* background-color: blue; */
}


.header-row-2 {
    height: 70px;
    min-height: 70px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
}

.header-row-2 .header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.header-row-2 .phone-buy-back-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}


.header-row-2 .logo {
    width: 230px;
    min-width: 230px;
    height: 100%;
}

.header-row-2 .win {
    flex: 1 1;
    font-size: 25px;
    font-weight: 700;
    white-space: nowrap;
}

.header-row-2 .win span {
    margin-right: 20px;
}

.header-row-2 .phone {
    width: 160px;
    display: block;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 5px;
    margin-right: 20px;
}
.vip {
    /*width: 300px;*/
    display: block;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 0px;
    padding-right: 15px;
    word-wrap: break-word;
    line-height: 20px;
}

@media only screen and (max-width: 600px) {
    .vip {
        font-size: 18px;
        line-height: 18px;
    }
}

.header-row-2 .phone a {
    margin-left: 5px;
    color:#000000;
    text-decoration: underline;
}

.header-row-2 .phone a:hover {
    color:#000000;
    text-decoration: underline;
}

.header-row-3 {
    height: 40px;
    width: 100%;
    background: #EBEEF2;
    position: relative;
    top: -40px;
    transition: top 0.5s ease-out;
}

.header-row-3.visible {
    top: 0px;
}

.header-row-3 .header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.header-row-3 .header-container a {
    margin-left: 30px;
    margin-right: 10px;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
}

.intro-video {
    height: 100vh; /* .page-header-page-margin height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.intro-video video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; 
    right: 0;
    margin: auto;
    min-width: 100%;
}

.intro-video .label-container {
    position: absolute;
    color: #ffffff;    
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;
    top: 50%;
    z-index: 10;
}

.heading-row {
    background: #9D6BAC;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.heading-row .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading-row .title,
.intro-title {
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
    text-align: center;
}

.heading-row .text {
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
}


.early-bird-banner .text {
    font-size: 20px;
}


.intro-banner {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #50C4F1;
}

.intro-banner .title,
.intro-title {
    font-size: 40px;
}

/* ------------------------------------------------------- checkbox */

.checkbox-label {
    margin-left: 20px;
}

.form-check-input {
    margin-top: 6px;
}

.form-label {
    margin-top: 14px;
    margin-bottom: 2px;
}

/* ------------------------------------------------------- carousel overlays */

.first-prize-carousel-bottom-overlay {
    position: relative;
}

.first-prize-carousel-bottom-container {
    position: absolute;
    top: -180px;
    color: white;
    text-shadow: 2px 2px black;
    font-size: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .first-prize-carousel-bottom-container {
        font-size: 30px;
    }
}


.early-bird-carousel-bottom-overlay {
    position: relative;
}

.early-bird-carousel-bottom-container {
    position: absolute;
    top: -250px;
    width: 100%;
}

/* ------------------------------------------------------- */

.congrats-first-prize .container {
    max-width: 1000px;
}

.congrats-first-prize .text,
.congrats-early-bird .text {
    margin-bottom: 20px;
}

.first-prize-winner .container {
    max-width: 950px;
}

.early-bird-banner {
    padding-top: 70px;
    padding-bottom: 50px;
}

.early-bird-winner .container {
    max-width: 1000px;
}

.heading-row.early-bird-winner,
.heading-row.tickets-available {
    padding-top: 50px;
    padding-bottom: 50px;
}

.no-top-padding {
    padding-top: 0px !important;
}

.vip-winner .container {
    max-width: 1000px;
}

.font-weight-normal {
    font-weight: normal;
}

.font-underline {
    text-decoration: underline;
}

.or-row {
    background: #9D6BAC;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.or-row .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.or-row .label-container {
    position: relative;
    width: 100px;
}

.or-row .label-container .img {
    top: -48px;
    right: 30px;
    position: absolute;
    width: 80px;
}

.or-row .label-container .text {
    top: -30px;
    right: 40px;
    position: absolute;
    font-size: 35px;
    font-weight: bold;
}

.or-row .text {
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    text-align: center;
}

.buy-tickets-row {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buy-tickets-row.no-gap {
    height: 0px;
}

/* ------------------------------------------------------- */

.early-bird-prize {
    height: 240px;
}

.bg-img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer-bg {
    height:100px !important;
    overflow: hidden;
}

.footer-bg img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .footer-bg img {
        width: 200%;
    }
}


.contact-and-partners {
    margin-top: 40px;
    margin-bottom: 50px;
}

.contact-and-partners .container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.contact-and-partners .contact {
    flex: 1 1;
    margin-bottom: 20px;
    width: 100%;
}

.divider {
    border-top: 1px solid #ccc;
    width: 100%;
    height: 1px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.contact {
    /*
    margin-top: 40px;
    padding-top: 40px;
    */
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    /*border-top: 1px solid #ccc;*/
}

.contact .social {
    margin-top: 20px;
}

.mailchimpHolder {
    margin-left: 50px;
    display: flex;
    flex:1 1;
}

#mc_embed_signup form {
    padding: 0 0 0 0 !important;
}

.mailchimp label {
    height: 40px;
}

.mailchimp label[for=mce-PHONE] {
    padding-top: 10px;
}

.contact .title {
    color: #0033a1;
    padding-bottom: 20px;
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
}

.contact .text {
    color: #9D6BAC;
    font-size: 20px;
    font-weight: 500;
}

.contact a {
    color: #9D6BAC;
    text-decoration: underline;
    -webkit-text-decoration-color: #CCCFD0;
            text-decoration-color: #CCCFD0;
    font-weight: bold;
}

.contact a:hover {
    -webkit-text-decoration-color: #9D6BAC;
            text-decoration-color: #9D6BAC;
}

/* ------------------------------------------------------- partners */

.partners {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partners .title {
    font-size: 35px;
    font-weight: 600;
    line-height: 46px;
    font-family: Futura-pt;
}

.partners .img-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
}

.partner-pca {
    width: 200px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.partner-apollo {
    width: 200px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.partner-philhoffman {
    width: 250px;
    margin-bottom: 30px;
}

.partner-solitaire {
    width: 300px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.partner-harveynorman {
    width: 250px;
    margin-bottom: 20px;
}

/* ------------------------------------------------------- */

.grey-block {
    margin-top: 0px;
}

.grey-block .container {
    background: #F3F1F1;
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.grey-block .right img {
    height: 100%;
    padding-left: 20px;
}

.grey-block .title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 20px;
}

.grey-block .text {
    font-size: 18px;
    /* line-height: 30px; */
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
}

.grey-block .img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}
.grey-block .altImg {
    width: 100%;
    object-fit: cover;
}

.impact-img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    z-index: 100;
}


.grey-block.reverse .container {
    flex-direction: row-reverse;
}

.grey-block.reverse .right img {
    padding-left: 0px;
    padding-right: 20px;
}


.grey-block.impact-3 img {
    width: 100%;
}

.grey-block.impact-2 img {
    width: 100%;
}

.grey-block.impact-1 img {
    width: 100%;
}

.grey-block.impact-4 img {
    width: 100%;
}

.footer {
    background: #DB0032;
    padding-top: 60px;
    padding-bottom: 20px;
}

.footer .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.footer .right,
.footer .left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.footer .links {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    width: 300px;
}

.footer .links a {
    color: #fff;
    border-top: 1px solid white;
    font-size: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.footer .links a:hover {
    text-decoration: none;
    font-weight: bold;
}

.footer .links a:last-child {
    border-bottom: 1px solid white;
}

.footer .logo {
    margin-bottom: 20px;
}

.footer .logo img {
    width: 350px;
    max-width: calc(100% - 30px);
}

.footer .license {
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
}

.footer .social {
    width: 100px;
    margin-left: 20px;
}

.social a:first-child {
    margin-right: 10px;
}

.social img {
    height: 40px;
    margin-bottom: 20px;
}


.buy-steps {
    background: #9D6BAC;
    padding-top: 40px;
    padding-bottom: 40px;
}

.steps-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.steps-container button {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.steps-container .step-container {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.steps-container .step {
    color: #9D6BAC;
    background-color: #fff;
    font-size: 20px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}

.steps-container .label {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}

.steps-container .line {
    width: 160px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: -80px;
    top: 20px;
    opacity: 0.5;
}

.step-container.active .label {
    opacity: 1;
}

.step-container.active .step {
    opacity: 1;
}

.step-container.active .line {
    opacity: 1;
}

.select-tickets-container {
    /*background: #9FC36C;*/
    padding-top: 20px;
    padding-bottom: 20px;
}

.select-tickets-container .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.odds-calculator {
    font-style: italic;
    padding-top: 15px;
}

.totalAmountHolder {
    margin-top: 30px;
}
@media only screen and (min-width: 500px) {
    .totalAmountHolder {
        margin-top: 4px;
    }
}
@media only screen and (max-width: 500px) {
    .totalAmountHolder {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.totalAmount {
    display:inline-block;
    position:relative;
    top:-3px;
    margin-right: 5px;
}

.buy-tickets .partners {
    text-align: center;
}

.buy-tickets .partners .img-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.when-you-purchase {
    background-color: #45BEE2;
    color: #ffffff;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 18px;
}

.buy-tickets-content-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.buy-tickets-content-container .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.buy-tickets-content-container button {
    background-color: #9D6BAC;
    border-color: #9D6BAC;
    border-radius: 0;
}

.number-of-tickets-btn {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):hover {
    color:#000000;
}

.number-of-tickets {
    display: inline-block;
    padding:10px;
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    background-color: #c4d2e7;
}

.submit-button,
.submit-button:hover,
.submit-button:focus,
.submit-button:active {
    background-color: /* #9D6BAC */#db0032 !important;
    border-color: /* #9D6BAC */#db0032!important;
    border-radius: 0;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    padding:10px;
}

.submit-button .spin {
    display: inline-block;
    margin-right: 5px;
}

.confetti-header {
    position: relative;
    width: 100%;
    height: 100px;
    overflow: hidden;
    background-color: #fff;
}

.confetti-header img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .confetti-header {
        height: 200px;
    }

    .confetti-header img {
        width: 200%;
    }
}


.impact-hero {
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.impact-hero img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: 50% 25%;
}

.impact-intro {
    margin-top: 10px;
}

.impact-intro .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.subtitle {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.lg {
    font-size: 30px !important;
}

.impact-footer {
    margin-top: 40px;
    margin-bottom: 40px;
}

.impact-footer div {
    margin-bottom: 10px;
}

.impact-footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prelaunch {
    min-height: 100vh;
    /*background: url(/images/confetti.jpg);*/
    background-color: #50C4F1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prelaunch .message-container {
    width: 60%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 999;
}

.prelaunch .message-container .logo {
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.prelaunch .message-container .message {
    color: #ffffff;
    background-color: #ed1c24;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    width: 100%;
}

.prelaunch .mailchimpHolder {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
}


/*
@media only screen and (max-width: 1200px) {
    .stickyLogo {
        max-width: 220px;
        margin-top: 0px;
    }
}
*/

@media only screen and (max-width: 990px) {
    .stickyLogo {
        max-width: 155px;
        margin-top: 0px;
    }

    .header-row-3 {
        height: 160px;
        width: 100%;
        background: #EBEEF2;
        position: relative;
        top: -160px;
    }

    .header-row-3 .container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 950px) {
    .stickyLogo {
        max-width: 154px;
        margin-top: 5px;
    }

    .header-row-2 .logo {
        width: 154px;
        min-width: 154px;
    }

    .porsche .label-container {
        right: -300px;
        top: -280px;
    }

    .porsche .fg-img {
        left: -550px;
        bottom: -150px;
        width: 900px;
    }

    .jayco-prize .label-container {
        right: -250px;
        bottom: -0px;
    }
    
    .jayco.fg-img {
        left: -50px;
        top: -150px;
        width: 700px;
    }

    .grey-block .right {
        display: none;
    }

    .grey-block-mobile {
        display: block !important;
    }
    .grey-block-desktop {
        display: none !important;
    }

    .prelaunch .message-container {
        width: 90%;
    }
}




@media only screen and (max-width: 768px) {

    .number-of-tickets-btn {
        font-size: 24px;  
    }
    
    .number-of-tickets {
        font-size: 24px;
        line-height: 24px;
    }

    .intro-video {
        margin-top: 190px;
        width: 100vw;
        height: 100vw;
    }
    
    .intro-video video {
        width: 100%;
        height: 100%;
    }
    
    .intro-video .label-container {
        top: 30%;
        font-size: 30px;
        line-height: 40px;
    }

    .page-header-page-margin {
        height: 175px;
    }

    .header-row-2 .phone-buy-back-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .steps-container .step-container {
        width: 120px;
    }

    .steps-container .line {
        width: 80px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        left: -40px;
        top: 20px;
        opacity: 0.5;
    }

    .stickyLogo {
        max-width: 140px;
        margin-top: 5px;
        margin-left: 20px;
    }

    .header-row-2 .logo {
        width: 140px;
        min-width: 140px;
    }

    .header-row-2 {
        height: 70px;
        min-height: 70px;
    }

    .header-row-2 .container {
        margin-top: 5px;
        flex-direction: row;
    }

    .or-row .label-container {
        display: none;
        left: 35px;
        top: -20px;
    }
/*
    /* fixed height or row in carousel to stop it popping with different height slides * /
    .carousel-item .or-row {
        height: 90px;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        width: 0 !important;
    }
*/
    .countdown {
        font-size: 16px;
    }

    .label-container .large {
        font-size: 32px;
        line-height: 36px;
    }

    .slide {
        height: 450px;
    }

    .jayco-prize .label-container {
        width: 350px;
        height: 200px;
        padding: 30px;
        padding-top: 50px;
        bottom: 50px;
        right: -150px;
    }
    
    .jayco.fg-img {
        left: -75px;
        top: -220px;
        width: 650px;
    }
    
    .porsche .label-container {
        width: 200px;
        height: 200px;
        padding: 20px;
        padding-top: 60px;
        right: -160px;
        top: -170px;
    }

    .porsche .fg-img {
        left: -550px;
        bottom: -200px;
        width: 900px;
    }

    .cash-prize .label-container {
        width: 200px;
        height: 200px;
        padding: 25px;
        padding-top: 65px;
        right: -100px;
        bottom: -50px;
    }

    .heading-row .title{
        padding-top: 10px;
        font-size: 28px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    .early-bird-banner .title {
        font-size: 22px;
    }

    .intro-banner .title {
        font-size: 22px;
    }
    
    .intro-title {
        font-size: 20px;
        line-height: 24px;
        margin-top: 30px;
    }

    .heading-row .text {
        font-size: 20px;
        line-height: 20px;
    }

    .early-bird-banner {
        padding-top: 35px;
        padding-bottom: 25px;
    }

    .early-bird-banner .text {
        font-size: 15px;
    }

    .early-bird-carousel-bottom-container {
        top: -150px;
    }

  
    
    .countdown .counterText{
        font-size: 16px;
    }

    .countdown{
        font-size: 14px;
    }

    .countdown .counterLabel{
        margin-right: 5px;
    }

    .congrats-first-prize .title,
    .congrats-early-bird .title {
        font-size: 40px;
        line-height: 40px;
    }

    .congrats-first-prize .text,
    .congrats-early-bird .text {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .impact-hero {
        width: 100%;
        height: 150px;
        overflow: hidden;
    }
    
    .impact-hero img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: 10% 25%;
    }

    .grey-block-mobile {
        display: block !important;
    }
    .grey-block-desktop {
        display: none !important;
    }

    .footer .links {
        width: 100%;
        max-width: 100%;
    }

    .early-bird-prize .bg-img {
        object-position: left;
    }
    
    .contact {
        flex-direction: column;
    }

    .mailchimpHolder {
        flex-direction: column;
        margin-left: 0px;
        margin-top: 30px;
    }

    .prelaunch .message-container .logo {
        max-width: 100%;
        margin-top: 5px;
        margin-bottom: 0;
    }
}


@media only screen and (max-width: 500px) {

    /*.header-row-2 {
        height: 170px;
        min-height: 170px;
    }*/

    .header-row-2 .container {
        padding-right: 0px;
        padding-left: 0px;
    }

    /*.stickyLogo {
        margin-top: 40px;
    }

    .page-header-page-margin {
        height: 255px;
    }*/

    .congrats-first-prize .title,
    .congrats-early-bird .title {
        font-size: 35px;
        line-height: 35px;
    }
}


/* ================ */


.ctaMobile {
    margin-top:-30px;
    position:fixed;

}

.ctaMobile .header-cta {
    line-height: 1.5em;
    padding:30px 0 10px 0
}

.ctaFixed {
    height:230px;
    top:100px;
    position: fixed;
    z-index: 998;
}

.mobileBuffer {
    margin-top: 0;
}


.soldCountdownContainer {
    top:0 !important;
    height:65px;
    background:#ed1c24;
    padding:0px;
  }


.counterText {
    color:#ffffff;
}

.counterText h1{
    color:#ffffff;
}

.headerImg.right {
    right:5px;
    bottom:20px;
    position: absolute;
    text-align: right;
}

.defaultPadding {
    padding:10px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.marginAbove {
    margin-top: 10px;
}

.cta {
    text-transform: uppercase;
}

.earlyBirdSection {
    background-color: #9e6bab;    
}

.firstPrize {
    background-color: #a0c36c !important;    
}

.earlyBirdSection h1 {
    color: #45BEE2;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 40px;
}

.firstPrize h1 {
    color: #9e6bab !important;    
}
.earlyBirdSection h2 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 60px;
}
.earlyBirdSection h3 {
    color: #a0c36c;
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
}
.earlyBirdSection p {
    color: #ffffff;
    font-size: 12px;
}

.earlyBirdSectionImg {
    padding:30px;
    text-align: right;
}

.earlyBirdSectionImg img{
    max-height: 400px;
}

.earlyBirdSectionContent {
    padding:40px 0 50px 0;
}

.earlyBirdSection .congrats h3{
    color:#ffffff !important;
    text-transform: uppercase;
}

/*
.partners img {
    margin:0 15px 0 15px;
}
*/
.partnersSection p{
    color: #000000;
}
.partnersSection {
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    padding:50px;
    font-size: 18px;
}

.whiteStripCta {
    background-color: #ffffff;
    font-size: 24px;
    color: #000000;
    font-weight: 700;
    text-align: center;
}

.blueStripCta {
    background-color: #45BEE2;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.purpleStripCta {
    background-color: #9e6bab;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.purpleTxt {
    color:#9e6bab;
}

.greenStripCta {
    background-color: #a0c36c;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.greyStripCta {
    background-color: #444444;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.ctaText {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #9e6bab;
    color:#ffffff;
    font-size: 18px;
    font-weight: 300;
}

.smallCtaText {
    font-size: 18px;
    font-weight: 300;
}

.ctaPrize {
    text-align: center;    
}

.ctaPrize img {
    max-height: 150px;
}

.blueText {
    color:#45BEE2;
}

.purpleText {
    color:#9e6bab;
}

.greenButton {
    background-color: #a0c36c;
    border-color:  #a0c36c;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    padding:10px;
    cursor: pointer;
}

.blueButton {
    background-color: #45BEE2;
    border-color:  #45BEE2;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    padding:10px;
    cursor: pointer;
}

.redButton {
    background-color: #ed1c24;
    border-color:  #ed1c24;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    padding:10px;
    cursor: pointer;
}

/*
.carouselBuyBtn {
    margin:auto;
    position: relative;
    width: 200px;
    margin:auto;
}
*/

.blueButton:hover, .redButton:hover, .greenButton:hover, .greenButton:active, .greenButton:active:focus {
    background-color: #9e6bab;
    border-color:  #9e6bab;
}

.fullWidth {
    width: 100%;
}

.ctaDome {
    position: absolute;
   bottom: 0 ;
   width:500px;
   left:50%;
   margin-left: -250px;
}

.ctaDomeSmall {
    position: absolute;
   bottom: 0 ;
   width:300px;
   left:50%;
   margin-left: -150px;
}


.ctaDome img, .ctaDomeSmall img {
    width:100%;
    
}



.ctaCircle {
    border-radius: 50%;
    width: 210px;
    height: 210px;
    padding:20px;
    text-align: center;
    font-weight: 400;
}

.ctaCircleSmall {
    border-radius: 50%;
    width: 125px;
    height: 125px;
    padding:20px;
    text-align: center;
    font-weight: 400;
}

.greenBg {
    background-color: #a0c36c;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
}

.purpleBg {
    background-color: #9e6bab;
    color: #ffffff;
    font-size: 14px;    
    font-weight: 700;
}

.blueBg {
    background-color: #45BEE2;
    color: #ffffff;
    font-size: 14px;    
    font-weight: 700;
}


.zoomIcon {
    color: rgb(255,255,255,0.7);
    text-align: right;
    cursor: pointer;
    width: 100%;
}

.textShadow {
    text-shadow: 1px 1px rgba(0,0,0,0.4)
}

.textBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.noPadding {
    padding: 0;
}

.noMargin {
    margin: 0;
}

.center {
    border: 3px solid green;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.verticalCenter {
    margin-top: auto;
    margin-bottom: auto;
}

.textCenter {
    text-align: center;
}

.flexRowCenter {
    align-items: center;
}

.fill { 
    min-height: 100%;
    height: 100%;
}
/*
.prizeCarousel {
    width: 100%;
    padding:0;
}

.galleryCarousel img {
    width:100%;
}


.carouselPrizeItem {
    text-align: center;
}

.carousel-control-next, .carousel-control-prev {
    opacity: 1;
    height:80%;
}


/* .heroCarousel .carousel-indicators{
    top:20px;
    height:50px;

}

.heroCarousel .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0,0,0,0.75)' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
   
.heroCarousel .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0,0,0,0.75)' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.heroCarousel .carousel-item{
    cursor: pointer;
}

.heroCarousel img {
    width:100%;
} */

/*
.buyCarousel .carousel-indicators{
    bottom:20px;
    height:50px;

}

.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 25px;
    height: 25px;
}


.buyCarousel .carousel-item{
    /* cursor: pointer; * /
}

.buyCarousel img {
    width:100%;
}

.heroCarousel .carousel-item {
    min-height: 1066px !important;
}
*/
.heroOverlay {
    position: absolute;
}

.heroCtaCircle {
    position: absolute;
    left:20px;
    top:20px;
}

.buyCarouselLogo {
    position: absolute;
    right:40px;
    top:30px;
}


.close {
    font-size: 2.5rem;
}

.gallery .close {
    font-size: 2.5rem;
    color: #ffffff;
}



.gallery .modal-header {
    background: transparent;
    border-bottom:none;
}

.gallery .modal-content {
    background: transparent;
    border: none;
}

.compCompletedPopupBody {
    background-color: #45BEE2;
}

.compCompletedPopupBody h2,tr,td {
    color:#ffffff;
}

.stickyHeight {
    height: 100px;
}

.sticky {
    top:65px;
    height: 100px;
    background: #ffffff;
    position: fixed;
    z-index: 999;
}

.fixed {
    position: fixed;
    z-index: 999;
}


.topcounter.on {
    top:10 !important;
    transition: top 0.5s ease-out;
}

.topcounter.off {
    transition: top 0.5s ease-out;
    top: -250px !important;
}


.stickyBtn {
    margin-right: 20px;
}

.menuBtn {
    color:#000000;
    cursor: pointer;    
}

.menuDropdown button{
    background: transparent !important;
    border:none;
}

.menuDropdown .dropdown-toggle::before {
    display: none;
}

.menuDropdown .dropdown-item a {
    color:#000000;
}

/*
.select-tickets {
    padding:30px 10px 30px 10px;
}
*/




.light-green-bg {
    background-color: rgba(160,185,108, 0.15)
}

.light-purple-bg {
    background-color: rgba(158,107,171, 0.15)
}

.light-blue-bg {
    background-color: rgba(69,190,226, 0.15)
}

.vipInfo {
    font-style: italic;
    font-size: 14px;
}

.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .react-autosuggest__input:disabled {
    background-color: #e9ecef;
    opacity: 1;
    }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 37px;
    width: 350px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

.required1 { /* Remove the 1 suffix once presignup has finished. The Mailchimp class that gets applied is called required so it's getting overriden. */
    color: #ed1c24;
    margin-left: 5px;
}

.errorInput {
    color: #ed1c24;
    border-color: #ed1c24;
}

.showOnMobile {
    display: none;
}

.cardSupport {
    max-width: 100px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.mobileCarousel{
    display: none;
}
.desktopCarousel{
    display: block;
}

/*#root {
    overflow: visible !important;
}
.mainContainer{
    overflow: visible !important;
}*/

.prizeDrawnRow{
    position: absolute;
    top: 0;
    left:0;
    z-index:1;
    bottom:0;
    right:0;
    height:100%;
}

.prizeDrawnOverlay {
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    padding-top: 30px;
}
  
.prizeDrawnOverlay li {
    list-style: none;
    color: #fff;
}

.prizeDrawnOverlay li h3 {
    margin-bottom: 20px;
}

.winners-table {
    margin-top: 10px;
    margin-bottom: 30px;
    max-width: 100%;
}

.winners-table th, td{
    color: #000;
    padding-right: 40px;
}


.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
content: " ";
display: block;
width: 64px;
height: 64px;
margin: 8px;
border-radius: 50%;
border: 6px solid #45BEE2;
border-color: #45BEE2 transparent #45BEE2 transparent;
-webkit-animation: lds-dual-ring 1.2s linear infinite;
        animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes lds-dual-ring {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}


.soldCta {
    top:0 !important;
    height:65px;
    background:#ed1c24;
    padding:15px;
}

.soldCta h2{
color:#ffffff;
font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
}

.soldMessage {
    background: #ed1c24;
    padding:20px;
    color: #ffffff;
    font-weight: 700;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
width:60%;
bottom:100px;
left:20%;
}

.terms p, ul{
margin-top: 20px;
/* font-size: 12px; */
}

.terms {
    margin-bottom: 50px;
}

.grey-block-text-padding {
    padding:20px;
}

.impact-image {
    width: 100%;
}

.noTopMargin {
    margin-top: 0 !important;
}

.grey-block-mobile {
    display: none;
}
.grey-block-desktop {
    display: block;
}

.mailchimp {
    margin:0 auto;
}




@media only screen and (min-width: 1500px) {
    .proceedsCarousel {
        min-height: 700px !important;   
    }
}




@media only screen and (max-width: 1024px) {
    .headerImg.right, .headerImg.left {
        text-align: center;
    }
}



@media only screen and (max-width: 768px) {

    .zoomIcon {
        text-align: center;
        margin-top: 25px;
    }

    .ctaCircle {
        margin:auto;
    }

    .earlybirdCar .cta {
        width: 300px;
        left: 50% !important;
        margin-left:-150px !important;
    }

    .stickyBtn {
        font-size: 16px;
    }

    .hideOnMobile {
        display: none;
    }

    .showOnMobile {
        display: block;
    }

    .mobileBuffer {
        top:230px;
    }
}




@media only screen and (max-width: 600px) {
    .headerImg img{
        width: 90%;
        
    }

/*
    .footerLinks {
        float: none;
    }
    .footerLinks p {
        text-align: center !important;
      }
      
    .footerLogo{
        text-align: center;
    }
    .footerLogo img{
        float: none;
    }
*/
    .earlyBirdSection h2 {
        font-size: 40px;
        line-height: 40px;
    }
    .earlyBirdSection h3 {
        font-size: 24px;
        
    }

    .earlyBirdSectionImg {
        padding:10px;
        text-align: center;
    }
    
    .earlyBirdSectionImg img{
        max-height: 300px;
    }
/*
    .promoCarousel .carousel-item h1{
        top:calc(50% - 100px);
        font-size: 40px;
        line-height: 40px;
    }

    .heroCarousel .carousel-item {
        min-height: 500px !important;
    }
    .cashCarousel {
        min-height: 500px !important;   
    }
    .proceedsCarousel {
        min-height: 300px !important;   
    }

    .proceedsCarousel img {
        width: 80% !important;
        height: 90% !important;
        left:10%;
        top:5%;
        border:10px solid #ffffff;
        box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
    }
    

    .buyCashCarousel {
        min-height: 200px !important;
    }

    .buyFormContainer {
        padding-left:20px;
        padding-right:20px;
    }
/*
    .partners img {
        max-width: 50%;
    }
*/
    .panelWithImageBackground {
        background-size: cover;
    }

    .menuBtn {
        margin-top: 5px;
    }

    .sticky {
        height:70px;
    }
    .stickyBtn {
        font-size: 12px;
    }
    
    /*
    .header-row-3 {
        top: 135px;
    }*/

    /*
    .carouselPrizeItem p{
        font-size: 10px;
    }

    .carouselPrizeItem p, .smallTextOnMobile {
        font-size: 80%;
    }

    .smallPrizecarouselTitle {
        font-size: 70%;
    }

    .carousel-item {
        overflow: hidden;
    }

    .mobileCarousel{
        display: block;
    }
    .desktopCarousel{
        display: none;
    }

/*
    .footer {
        padding-left: 20px;
        padding-right: 20px;
    }
*/
    .topbar {
        padding-left: 10px;
        padding-right: 10px;
    }


    .soldMessage {
        padding:10px !important;
        font-size: 20px !important;
    bottom:auto !important;
    top:30px;
    }

    .soldCta h2{
        font-size: 16px !important;
      }
    

    .winners-table {
        font-size: 80%;
    }

    .winners-table th, td{
        padding-right: 10px;
    }

    .announcementHeader {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        display: block !important;
        justify-content: inherit !important;
    }
    
    .announcementHeaderTxt {
        margin-top: inherit !important;
    }
    
    .announcementTxt {
        height: 0px !important;
    }
    .vip {
        display: none;
    }
}


.announcementHeader {
    display: flex;
    justify-content: center;
}

.announcementTxt{
    height: 65px;
    padding: 10px;
    font-weight: bold;
    color:#ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.announcementHeaderTxt {
    margin-top: 10px;
}
.confettiOverlay{
    width: 100%;
    overflow: hidden;
}


.blink_me {
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
  }
  
  @-webkit-keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
body,
html {
    height: 100%;
}

.falling-leaves {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate(0%, 0);
            transform: translate(0%, 0);
    overflow: hidden;
    z-index: 9;
    margin-bottom: 0px;
}

.leaf-scene {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.leaf-scene div {
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 30vmin;
    height: 30vmin;
    background: url(/images/oneHundredEdit.png) no-repeat;
    background-size: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
}
