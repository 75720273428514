body,
html {
    height: 100%;
}

.falling-leaves {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(0%, 0);
    overflow: hidden;
    z-index: 9;
    margin-bottom: 0px;
}

.leaf-scene {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    transform-style: preserve-3d;
}

.leaf-scene div {
    position: absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 30vmin;
    height: 30vmin;
    background: url(/images/oneHundredEdit.png) no-repeat;
    background-size: 100%;
    transform-style: preserve-3d;
    backface-visibility: visible;
}